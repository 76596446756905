<template>
<div class="container px-5">
    <div class="pt-5"></div>
    <div class="row py-4">
        <div class="col-12">
            <img class="text-center" src="https://storage.googleapis.com/trillwave/logo/logo_text.png" height="48px" alt="" srcset="">
        </div>
    </div>
    <div class="row">
        <div class="col-12 card p-4">
            <h3 class="mt-4">
                Order
                <span class="text-muted">#{{ transaction.id }} -
                    {{ humandate(transaction.created_at) }}</span>
            </h3>
            <h5 class="mt-4">
                Paypal Order
                <span class="text-muted">#{{ transaction.paypal_order_id }} </span>
                   
            </h5>

            <div class="row pt-4">
                <div class="col-lg-2">
                    <span class="text-bold">Customer</span>
                </div>
                <div class="col">
                     <router-link class="tlink" :to="'/d/customers/' + transaction.customer.id">
                                <span>{{ transaction.customer.name }} ({{
                transaction.customer.email
              }})</span> </router-link>
                    
                </div>
                <div class="col-12 mt-3"></div>
                <div class="col-lg-2 ">
                    <span class="text-bold">Seller</span>
                </div>
                <div class="col">
                    <span>{{
                transaction.seller.nick_name || transaction.seller.user_name
              }}
                        ({{ transaction.seller.email }})</span>
                </div>
                <div class="col-12 mt-3"></div>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item mb-0 py-3 px-3" v-for="x in transaction.cart.cart_items" :key="x.id">
                    <div class="row">
                        <div class="col-auto">
                            <img style="height: 50px !important; width: 50px !important" :blur="0" class="radius align-middle" :src="x.thumbnail" />
                        </div>
                        <div class="col-auto">
                            <h5 class="px-2n">{{ x.title }}</h5>
                            <span class="px-2n">
                                {{ x.licence_name }} (${{ x.price }})</span>
                        </div>
                        <div class="col-3">
                            <span class="tag ml-1" data-tooltip="Draft" v-if="x.draft">
                                <ph-file-dotted class="text-warning" /> <span>Draft</span>
                            </span>
                        </div>

                        <div class="col-lg mt-3 col-sm-12">
                            <span class="float-right ml-2">
                                <span class="control">
                                    <a :href="x.rendered_licence_url" class="btn btn-secondary btn-slim">
                                        <ph-download-simple class="text ml-2 mr-1" size="18" />
                                        <span class="text pr-2"> Licence</span>
                                    </a>
                                </span>
                            </span>
                            <span class="float-right ml-2" v-if="x.zip_url">
                                <span class="control">
                                    <a :href="x.zip_url" class="btn btn-primary btn-slim">
                                        <ph-download-simple class="text ml-2 mr-1" size="18" />
                                        <span class="text pr-2"> STEMS</span>
                                    </a>
                                </span>
                            </span>
                            <span class="float-right ml-2" v-if="x.wav_url">
                                <span class="control">
                                    <a :href="x.wav_url" class="btn btn-primary btn-slim">
                                        <ph-download-simple class="text ml-2 mr-1" size="18" />
                                        <span class="text pr-2"> WAV</span>
                                    </a>
                                </span>
                            </span>

                            <span class="float-right ml-2">
                                <span class="control">
                                    <a :href="x.mp3_url" class="btn btn-primary btn-slim">
                                        <ph-download-simple class="text ml-2 mr-1" size="18" />
                                        <span class="text pr-2"> MP3</span>
                                    </a>
                                </span>
                            </span>
                        </div>
                    </div>
                </li>
            </ul>

            <h4 class="mt-4">Total: <span class="text-muted"> ${{transaction.cart.total_amount}}</span></h4>

        </div>
    </div>
</div>
</template>

<script>
import moment from "moment";
import {
    PhDownloadSimple
} from "phosphor-vue";
export default {
    components: {
        PhDownloadSimple,
    },
    data() {
        return {
            transaction: {},
            transaction: {},
            colors: ["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"],
            fbase3: "https://storage.googleapis.com/trillwave/image/300/",
            fbaseo: "https://storage.googleapis.com/trillwave/image/orig/",
            filter: "",
            search_text: "",
            ready: false,
            empty: false,
        };
    },
    mounted() {
        var baseURI = this.$baseurl + "/transactions/" + this.$route.params.id;
        var config = {
            Authorization: "Bearer " + this.$store.getters.getUser.token,
        };
        this.$http
            .get(baseURI, {
                headers: config,
            })
            .then((result) => {
                //this.setBeats(result.data)
                this.transaction = result.data;
                this.ready = true;

            });
    },
    methods: {
        humandate(stime) {
            return moment(stime).format("ll");
        },
    },
};
</script>

<style>
</style>
